<template>
    <v-app>
        <v-card raised>
            <v-card-title>
                Appointments
                <v-spacer></v-spacer>

                <v-btn v-if="checkIsAccessible('appointment', 'create')"
                    class="ma-2 btn btn-primary "
                    small
                    @click="addAppointment"
                >
                    <v-icon  x-small>
                        fas fa-plus
                    </v-icon>
                    Add appointment
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                    <div class="row">
                        <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                                outlined
                                dense
                                :items="admin_users"
                                item-text="full_name"
                                item-value="id"
                                v-model="search.admin_user_id"
                                @input="search.admin_user_id = $event !== null ? $event : ''" 
                                clearable
                                label="Appointment by admin staff"
                                v-on:keyup.enter="getAppointments()"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                                outlined
                                dense
                                :items="users"
                                item-text="full_name"
                                item-value="id"
                                v-model="search.user_id"
                                @input="search.user_id = $event !== null ? $event : ''" 
                                clearable
                                label="Search by registered agent"
                                v-on:keyup.enter="getAppointments()"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                            <v-text-field
                                label="Search by candidate name, email or phone"
                                outlined
                                dense
                                v-model="search.user_info"
                                @input="search.user_info = $event !== null ? $event : ''" 
                                clearable
                                v-on:keyup.enter="getAppointments()"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                            <v-select
                                outlined
                                dense
                                @change="handleSearchDateTypeChange"
                                :items="searchDates"
                                item-text="date"
                                item-value="value"
                                v-model="search.search_date_type"
                                @input="search.search_date_type = $event !== null ? $event : ''" 
                                clearable
                                label="Date"
                                v-on:keyup.enter="getAppointments()"
                            ></v-select>
                        </v-col>
                        
                        <v-col v-if="showDate" class="d-flex" cols="12" sm="12" md="2">
                            <v-menu
                                ref="menu"
                                v-model="date"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="search.date"
                                        label="Search By Date "
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        @input="search.date = $event !== null ? $event : ''" 
                                        clearable
                                    >
                                    <template v-slot:label>
                                        Search By Date
                                    </template>  
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    no-title
                                    v-model="search.date"
                                    :active-picker.sync="activePicker"
                                    min="1950-01-01"
                                ></v-date-picker>
                                </v-menu>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="2">
                            <v-btn 
                                :loading="loading"
                                class="mt-1 btn btn-primary text-white" 
                                @click="getAppointments"
                            >
                                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                Search
                            </v-btn>
                        </v-col>
                    </div>
                </div>
            </v-card-text>
            <!-- appointment listing -->
            <div>
                <v-card-subtitle>
                    <div class="d-flex justify-content-between">
                        <div>
                            <v-btn
                                class="ma-2"
                                :class="search.type == 'all' ? 'active' : '' "
                                outlined
                                small
                                @click="setAppointmentType('all')"
                            >
                                <v-icon color="black" x-small>
                                    fas fa-circle
                                </v-icon>
                                All
                            </v-btn>

                            <v-btn
                                class="ma-2"
                                :class="search.type == 'scheduled' ? 'active' : ''"
                                outlined
                                small
                                @click="setAppointmentType('scheduled')"
                            >
                                <v-icon color="info" x-small>
                                    fas fa-circle
                                </v-icon>
                              Scheduled
                            </v-btn>

                            <v-btn
                                class="ma-2"
                                :class="search.type == 'waiting' ? 'active' : ''"
                                outlined
                                small
                                @click="setAppointmentType('waiting')"
                            >
                                <v-icon color="orange" x-small>
                                    fas fa-circle
                                </v-icon>
                                Waiting
                            </v-btn>

                            <v-btn
                                class="ma-2"
                                outlined
                                small
                                :class="search.type == 'attended' ? 'active' : ''"
                                @click="setAppointmentType('attended')"
                            >
                                <v-icon color="green" x-small>
                                    fas fa-circle
                                </v-icon>
                                Attended
                            </v-btn>

                            <v-btn
                                class="ma-2"
                                outlined
                                small
                                :class="search.type == 'missed' ? 'active' : ''"
                                @click="setAppointmentType('missed')"
                            >
                                <v-icon color="blue-grey" x-small>
                                    fas fa-circle
                                </v-icon>
                                Missed
                            </v-btn>

                            <v-btn
                                class="ma-2"
                                outlined
                                small
                                :class="search.type == 'cancelled' ? 'active' : ''"
                                @click="setAppointmentType('cancelled')"
                            >
                                <v-icon color="red" x-small>
                                    fas fa-circle
                                </v-icon>
                                Cancelled
                            </v-btn>
                        </div>
                        <div class="title">
                            Total : {{total}}
                            <v-btn
                                class="ma-2"
                                outlined
                                small
                                color="black"
                                @click="setAppointmentType('all')"
                            >
                                <v-icon color="black" x-small>
                                    fas fa-redo
                                </v-icon>
                                &nbsp; Reset Filter
                            </v-btn>
                        </div>
                    </div>
                </v-card-subtitle>

            <div class="table-responsive" style="table-layout: fixed">
                <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th>Date & Time</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                  <th>Status</th>
                  <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr  v-for="(item, index) in appointments" :key="index">
                    <td class="px-2"> {{item.formatted_date}} {{item.formatted_month}}  {{item.formatted_time}}</td>
                    <td class="px-2"> {{item.user_name}}</td>
                    <td class="px-2">{{item.user_email}}</td>
                    <td class="px-2">{{item.user_phone}}</td>
                    <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">{{item.branch_name}}</td>
                    <td class="px-2">
                      <span class="badge text-lg" v-bind:class="{
                        'badge-warning': item.status == 'waiting', 'badge-danger': item.status == 'cancelled',
                        'badge-info': item.status == 'scheduled', 'badge-success': item.status == 'attended', 'badge-secondary': item.status == 'missed'
                      }">
                        {{ item.status_text }}
                      </span></td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="quickView(item)">
                                <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                </span>
                                <span class="navi-text">Quick view</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="editAppointment(item)">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="changeStatus(item)">
                                <span class="navi-icon">
                                    <i class="fas fa-bars"></i>
                                </span>
                                <span class="navi-text">Change status</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="appointments.length === 0">
                    <td colspan="7" class="text-center">
                      No items added
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                    v-if="appointments.length > 0"
                    class="pull-right mt-7"
                    @input="getAppointments"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    :disabled="loading"
                ></b-pagination>
            </div>

            </div>
            <!-- appointment listing ends -->

            <CreateAndUpdateAppointment
                ref="create-and-update-appointment" 
                @refresh="getAppointments"
            ></CreateAndUpdateAppointment>        
            
            <UpdateAppointmentStatus
                ref="update-status"
                @refresh="getAppointments"
            ></UpdateAppointmentStatus>

            <quick-view
                ref="quick-view"
            ></quick-view>

        </v-card>
    </v-app>

</template>

<script>
import CreateAndUpdateAppointment from "./CreateAndUpdateAppointment.vue";
import AdminUserService from "@/services/admin-user/AdminUserService";
import UserService from "@/services/user/UserService";
import UpdateAppointmentStatus from "./UpdateAppointmentStatus";
import AppointmentService from "@/services/appointment/AppointmentService";
import QuickView from "./QuickView";

const admin_user = new AdminUserService();
const user = new UserService();
const appointmentService = new AppointmentService();

export default {
  props: ['user'],
  components: {
    QuickView,
        CreateAndUpdateAppointment,
        UpdateAppointmentStatus
    },
  data(){
      return{
          isLoading: false,
          appointments: [],
          date: '',
          search: {
              type: 'all',
              date: '',
              user_info: '',
              user_id: '',
              admin_user_id: '',
              search_date_type: 'today'
          },
          showDate: false,
          searchDates: [
              {
                  date: 'Yesterday',
                  value: 'yesterday'
              },
              {
                  date: 'Today',
                  value: 'today'
              },
              {
                  date: 'Tomorrow',
                  value: 'tomorrow'
              },
              {
                  date: 'Select date',
                  value: 'custom_date'
              },
          ],
          users: [],
          admin_users: [],
          activePicker: null,
          loading:false,
          total: null,
          perPage: null,
          totalCounts: [],
          page: null,
      }
  },
  mounted() {
    this.getAppointments();
    this.getAllUsers();
    this.getAllAdminUsers();
  },

  computed: {
    appointmentType(){
      if(this.search.type == 'all'){
        return 'All'
      }
      if(this.search.type == 'attended'){
        return 'Attended'
      }
      if(this.search.type == 'missed'){
        return 'Missed'
      }
      if(this.search.type == 'waiting'){
        return 'Waiting'
      }
      if(this.search.type == 'cancelled'){
        return 'Cancelled'
      }
    },
    color(){
      if(this.search.type == 'all'){
        return 'indigo';
      }
      if(this.search.type == 'attended'){
        return 'green';
      }
      if(this.search.type == 'waiting'){
        return 'pink';
      }
      if(this.search.type == 'missed'){
        return 'red';
      }
      if(this.search.type == 'cancelled'){
        return 'gray';
      }
    },
  },

  methods:{
    quickView(item) {
      this.$refs['quick-view'].openDialog(item);
    },

    getAppointments() {
        this.loading = true;
        appointmentService
        .all(this.search, this.page)
        .then((response) => {
            this.appointments = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
        })
        .catch(() => {
            this.loading = false;
        })
    },

    changeStatus(appointment){
        this.$refs['update-status'].updateStatus(appointment);
    },

    getAllUsers(){
        user
        .all()
        .then(response => {
            this.users = response.data.users;
        })
        .catch((err) => {
        });
    },

    getAllAdminUsers(){
        admin_user
        .all()
        .then(response => {
            this.admin_users = response.data.adminUsers;
        })
        .catch((err) => {
        });
    },

    getAppointmentsCount() {
        appointmentService
        .getAllCount(this.search)
        .then((response) => {
            this.totalCounts = response.data;
        });
    },

    setAppointmentType(type) {
        this.search.type = type;
        this.getAppointments();
    },

    addAppointment()
    {
        this.$refs['create-and-update-appointment'].createAndUpdateAppointment(this.currentUser.id);
    },

    editAppointment(item)
    {
        this.$refs['create-and-update-appointment'].editAppointment(item);
    },

    handleSearchDateTypeChange(){
        if(this.search.search_date_type == 'custom_date'){
            this.showDate = true;
        }else{
            this.showDate = false;
        }
    }
  }
}
</script>
